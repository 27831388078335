import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import BannerSportsPrograms from '../components/BannerSportsPrograms.js';
import JSONDataSports from '../content/sportgroup.json';

const UniversitySports = (props) => (
  <Layout>
    <Helmet>
      <title>University Sports Programs - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="We offer you the most
            reach for your advertising dollars with packages that cover all our
            sports publications."
      />
    </Helmet>

    <BannerSportsPrograms />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Put Your Business in the Game</h2>
          </header>
          <p>
            Our game programs put your business in front of a captivated
            audience, including patrons, alumni and donor club members. Make
            these sports fans a fan of your business. We offer you the most
            reach for your advertising dollars with packages that cover all our
            sports publications.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        {JSONDataSports.sportgroups.map((data, index) => {
          return (
            <section key={index}>
              <Link to={data.link} className="image">
                <img src={data.image} alt="" />
              </Link>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>{data.title}</h3>
                  </header>
                  <p>{data.desc}</p>
                  <ul className="actions">
                    <li>
                      <Link to={data.link} className="button">
                        Learn more
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          );
        })}
      </section>
    </div>
  </Layout>
);

export default UniversitySports;
