import React from 'react';

const BannerSportsPrograms = (props) => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>University Sports Programs</h1>
      </header>
      <div className="content">
        <p>
          Mills Publishing handles the publication of the sports programs at
          University of Utah and Utah State University. Each one offers
          advertising to a distinct, dedicated audience, described below.
        </p>
        <ul className="actions">
          <li>
            <a href="/advertising-specifications" className="button special">
              University Sports Ad Specs
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default BannerSportsPrograms;
